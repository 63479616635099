//
// This file will be copied as environment.ts. Put its import statement as high as
// possible int the index.tsx file to help prevent race conditions.
import { BaseURLMap, BaseURL } from "ares-core/IO/Http";

export const ARES_DEVELOPMENT = false;

export const REACT_APP_STRIPE_KEY='pk_live_WMHXOX9YobatEMlNVpdOBRTZ';
export const REACT_APP_STRIPE_VERSION='2022-11-15';

BaseURLMap.set(BaseURL.LINK, "areservationapi-link.azurewebsites.net");
